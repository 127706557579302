import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
  themes: {
    light: {
      primary: '#01ADB5',
      secondary: '#AAA',
      accent: '#82B1FF',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
      gloomy: '#32373B',
      gloomymore: '#2A2E32',
      mist: '#E7E7E7',
      highlight: '#F66B0E',
    },
    dark: {
      primary: '#01ADB5',
      gloomy: '#32373B',
      gloomymore: '#2A2E32',
      mist: '#E7E7E7',
      highlight: '#F66B0E',
    }
  },
},
});
